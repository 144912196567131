<template>
  <div style="overflow-x:auto;height:100%;">
    <v-container 
      class="ma-0 pa-0 px-1"
      style="width:100%;max-width:2000px;">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="displayItemsAfterFilter"
              :search="searchString"
              :loading="loading"
              loading-text="Đang tải...vui lòng đợi"
              dense
              item-key="id"
              sort-by="date"
              :sort-desc="true"
              :multi-sort="false"
              mobile-breakpoint="0"
              style="padding:6px;"
              @item-selected="selectItem"
              @toggle-select-all="selectAllItems"
              :items-per-page="itemPerPage"
              :page.sync="eventsPage"
              @page-count="pageCount = $event"
              hide-default-footer
            >
            <!--
              <template v-slot:top>
                <v-card v-if="!$root.isElectron"
                  flat style="padding:16px 2px 0px 2px;">
                  <v-card-title class="pa-0 px-4 pb-1" style="align-items: start;">
                    <v-text-field
                      outlined
                      dense
                      clearable
                      v-model="searchString"
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      class="mb-2"
                      style="max-width:300px;"
                      placeholder="Tìm kiếm"
                    ></v-text-field>
                    <v-spacer/>
                    <v-btn v-if="1==2 "
                      style="height:38px;"
                      class="text-none"
                      color="primary"
                      :disabled="itemsSelected.length == 0"
                      @click="acknowledgeItems"
                    >
                      Ack Selecteds
                    </v-btn>

                    <v-btn v-if="1==2"
                      style="height:38px;"
                      class="text-none ml-2"
                      color="primary"
                      @click="acknowledgeAll"
                    >
                      Ack All
                    </v-btn>
                  </v-card-title>
                </v-card>
              </template>

              <template
                v-slot:item.data-table-select="{ item, select, isSelected }"
              >
                <template v-if="!item.ackBy || item.ackBy == ''">
                  <v-icon v-if="isSelected"
                    style="font-size:22px;"
                    @click="select(false)"
                    >
                    mdi-checkbox-marked
                  </v-icon>

                  <v-icon v-else
                    style="font-size:22px;"
                    @click="select(true)"
                    >
                    mdi-checkbox-blank-outline
                  </v-icon>
                </template>
                
              </template>
              -->
              <template v-slot:item.icon="{ item }">
                <v-icon
                  style="font-size:26px;"
                  :color="item.color"
                >
                  {{ item.icon }}
                </v-icon>
              </template>

              <template v-slot:item.ackBy="{ item }">
                <!--
                <v-chip
                  small
                  dark
                  color="teal"
                  >Confirmed</v-chip>
                  -->
                <div v-if="item.ackBy && item.ackBy.length > 0">
                  {{item.ackBy}}
                </div>
                <v-chip
                  v-else
                  small color='teal' dark
                  style="cursor:pointer;margin: 2px 0px;"
                  @click.stop="acknowledgeEachItem(item)"
                >
                  Acknowledge
                </v-chip>
              </template>

            </v-data-table>
            <div v-if="pageCount>1"
              style="max-width:100%;"
              class="text-center pt-2 px-4">
              <v-pagination
                  v-model="eventsPage"
                  :length="pageCount"
                  :total-visible="10"
                  ></v-pagination>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  let moment = require("moment");
  import { mapGetters } from "vuex";

  export default {
    props: ["filter", "levFilterArr"],
    data() {
      return {
        pageCount: 0,
        eventsPage: 1,
        itemPerPage: this.$root.isElectron||this.$vuetify.breakpoint.smAndDown?11:15,
        items: [],
        searchString: "",
        loading: false,
        selected: [],
        itemsSelected: [],
        headers: [
          {
            text: "Ngày giờ",
            value: "date",
            align: "start",
            class: " font-weight-bold dateMinWidth",
            sortable: true,
          },
          // {
          //   text: "Thiết bị",
          //   value: "subDeviceName",
          //   align: "start",
          //   class: " font-weight-bold lighten-1",
          //   sortable: true,
          // },
          // {
          //   text: "Source",
          //   value: "pointName",
          //   align: "start",
          //   class: " font-weight-bold lighten-1",
          //   sortable: true,
          // },
          {
            text: "Loại",
            value: "icon",
            align: "start",
            class: "levelMinWidth",
            sortable: true,
          },
          // {
          //   text: "Condition",
          //   value: "alarmLevel",
          //   align: "start",
          //   class: " font-weight-bold lighten-1",
          //   sortable: false,
          // },
          {
            text: "Mô tả",
            value: "desc",
            align: "start",
            class: " font-weight-bold descMinWidth",
            sortable: false,
          },
          {
            text: "Giá trị",
            value: "value",
            align: "end",
            class: " font-weight-bold",
            sortable: false,
          },
          {
            text: "Đơn vị",
            value: "unit",
            align: "start",
            class: " font-weight-bold",
            sortable: false,
          },
          // {
          //   text: "Ack",
          //   value: "ackBy",
          //   align: "center",
          //   class: " font-weight-bold",
          //   sortable: true,
          // }
        ],
      };
    },
    watch: {
      async filter () {
        this.refreshSelect()
        await this.fetchData()
      },
      levFilterArr() {
        this.updateItemsAfterFilterLev()
      },
    },
    computed: {
      ...mapGetters({
        userId: "userId",
        token: "token",
        role: "getLevel",
        fullname: "getFullName",
      }),
      displayItemsAfterFilter() {
        let result = [];
        this.levFilterArr.forEach((i) => {
          if (!i.selected) return;
          result = [
            ...result,
            ...this.items.filter((e) => e.alarmLevel == i.name),
          ];
        });

        return result;
      },
    },
    sockets: {
      callHTTPRequestResult: function (payload) {
        //  console.log("callHTTPRequestResult")
        //  console.log(payload)
         if(payload && payload.askKey == this.socketAskKey) {
           if (payload.url.includes('events/filter')) {
            if(payload.result){
              this.handleDataResult(payload.result)
            }
           }
         }
      }
   },
    methods: {
      /*
      acknowledgeEachItem(item) {
        let self = this;
        var pathFetchAll = this.$root.logApi + "events/confirm";
        let body = {
          ackBy: this.fullname || "Admin",
          year: moment(item.date).format("YY"),
          ids: [item.id],
        };
        self.axios
          .put(pathFetchAll, body, {
            headers: { "x-auth": self.token },
          })
          .then((response) => {
            if (response.data.status === "OK") {
              // self.items = response.data.content;
              this.fetchData()
            } else {
              this.fetchData()
              console.log(response.data.status);
            }
          })
          .finally(() => {
            self.loading = false;
          });
      },
      acknowledgeAll() {
        
        new Promise(resolve => {
          this.itemsSelected = [];
          this.items.forEach((item, index) => {
            let data = {
              item: item,
              value: true
            }
            this.selectItem(data);
            if(index == this.items.length -1) resolve();
          })
        })
        .then(() => {
          let path = this.$root.logApi +"events/confirm";
          let promiseArray = [];
          this.itemsSelected.forEach((iPerYear) => {
            let body = {
              ackBy: this.fullname || "Admin",
              year: iPerYear.year,
              ids: iPerYear.ids,
            };
            promiseArray.push(
              this.axios.put(path, body, {
                headers: { "x-auth": this.token },
              })
            );
          });
          this.callApiAckEvent(promiseArray)
        })
        
      },
      acknowledgeItems() {
        let path = this.$root.logApi +"events/confirm";
        let promiseArray = [];
        this.itemsSelected.forEach((iPerYear) => {
          let body = {
            ackBy: this.fullname || "Admin",
            year: iPerYear.year,
            ids: iPerYear.ids,
          };
          promiseArray.push(
            this.axios.put(path, body, {
              headers: { "x-auth": this.token },
            })
          );
        });
        this.callApiAckEvent(promiseArray)
      },
      callApiAckEvent(promiseArray){
        let self = this;
        Promise.all(promiseArray)
          .then((allRessponse) => {
            allRessponse.forEach((response) => {
              if (response.data.status === "OK") {
                console.log(response.data.message)
              } else {
                alert(response.data.message);
              }
            });
            self.fetchData();
            self.refreshSelect();
          })
          .catch(console.log);
      },
      */
      refreshSelect() {
        this.itemsSelected = [];
        this.selected = [];
      },
      selectItem(itSelected) {
        if (itSelected.item.ackBy != "") return;
        let getYear = itSelected.item.date.substring(2, 4);
        if (itSelected.value) {
          if (this.itemsSelected.filter((i) => i.year == getYear).length == 0) {
            // console.log(getYear);
            this.itemsSelected.push({
              year: getYear,
              ids: [itSelected.item.id],
            });
          } else {
            // Save when year exist
            this.itemsSelected.map((i) => {
              if (i.year == getYear) {
                i.ids.push(itSelected.item.id);
              }
            });
          }
        } else {
          // Save when year exist
          this.itemsSelected.map((i, index) => {
            if (i.year == getYear) {
              let idx = i.ids.indexOf(itSelected.item.id);
              i.ids.splice(idx, 1);
              if (i.ids.length == 0) {
                this.itemsSelected.splice(index, 1);
              }
            }
          });
        }
        // console.log("this.itemsSelected", this.itemsSelected);
      },
      selectAllItems(allItem) {
        // console.log(allItem);
        if (allItem.value) {
          allItem.items.forEach((item) => {
            if (item.ackBy != "") return;

            let getYear = item.date.substring(2, 4);
            if (
              this.itemsSelected.filter((i) => i.year == getYear).length == 0
            ) {
              this.itemsSelected.push({
                year: getYear,
                ids: [item.id],
              });
            } else {
              // Save when year exist
              this.itemsSelected.map((i) => {
                if (i.year == getYear) {
                  i.ids.push(item.id);
                }
              });
            }
          });
        } else {
          this.refreshSelect();
        }

        // console.log("this.itemsSelected", this.itemsSelected);
      },
      getColorIcon(level) {
        let alarmLevels = {
          warning: "orange",
          error: "red",
          danger: "purple",
          normal: 'blue'
        }
        if(alarmLevels[level]) return alarmLevels[level]
        else  return alarmLevels['normal']
      },
      getIcon(level) {
        let alarmLevels = {
          warning: "mdi-alert-outline",
          error: "mdi-close-octagon-outline",
          danger: "mdi-close-octagon",
          normal: 'mdi-information-outline'
        }
        if(alarmLevels[level]) return alarmLevels[level]
        else  return alarmLevels['normal']
      },
      filterDone() {
        if(this.items && this.items.length>0){
          this.items.forEach(item => {
            item.color = this.getColorIcon(item.alarmLevel)
            item.icon = this.getIcon(item.alarmLevel)
          })
        }
        this.$emit("filter-done", this.items);
      },
      async fetchData() {
        this.items = []
        var pathFetchAll = this.$root.lanLogApi + "events/filter"
        // this.loading = true

        let fromYear = moment(this.filter.from).format("YYYY")
        let toYear = moment(this.filter.to).format("YYYY")
        let filterArr = []
        if (fromYear == toYear) {
          filterArr = [{
              from: this.filter.from,
              to: this.filter.to,
            }]
        } else {
          filterArr = [
            {
              from: this.filter.from,
              to: moment(this.filter.from)
                .endOf("month")
                .format("YYYY-MM-DD 23:59:59"),
            },
            {
              from: moment(this.filter.to)
                .startOf("month")
                .format("YYYY-MM-DD 00:00:00"),
              to: this.filter.to,
            }
          ]
        }
        for(let i = 0; i<filterArr.length; i++) {
          if (this.$root.isRemote) {
            this.callApiOverOTA(
              "PUT",
              pathFetchAll,
              filterArr[i]
            )
          } else {
            let res = await this.axios.put(
              pathFetchAll,
              filterArr[i]
            )
            this.handleDataResult(res.data)
          }
        }
      },
      handleDataResult (payload) {
        if (payload.status === "OK") {
          this.items = this.items.concat(payload.content)
        } else {
          console.log("Err Filter first year: ", payload.status)
        }
        this.filterDone()
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
      },
    },
    mounted() {
      this.$nextTick(async () => {
        await this.fetchData()
      });
    },
  };
</script>

<style>
  #alarmHistoryTable div table tbody tr td {
    margin: 0px;
    height: 34px;
    line-height: 34px;
  }
  .dateMinWidth {
    min-width: 110px;
  }
  .descMinWidth {
    min-width: 210px;
  }
  .levelMinWidth {
    min-width: 50px;
  }
</style>
