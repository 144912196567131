<template>
  <v-container
   class="px-0"
   style="height:100%;padding-top:58px;padding-bottom:46px;max-width:3600px;background-color:transparent;overflow-y:auto;">
    <v-row
      class="pa-0 ma-0 mt-0"
      align="center"
      style="background-color:#CFD8DC44;border: 1px solid #B0BEC566;max-width:100vw;"
    >
      <v-col
        cols="12" md="6" lg="6" xl="6"
        :class="{
          'px-2': $vuetify.breakpoint.mdAndUp,
          'px-1': $vuetify.breakpoint.smAndDown,
        }"
        class="pa-0 ma-0 py-1"
        style="min-width: 380px;max-width: 420px;"
      >
        <div style="text-align:left;">
          <date-picker
            style="display:inline-block;vertical-align:top;"
            @dateChanged="onDateChanged" />
          <v-btn
              style="min-width:60px;max-width:60px;height:38px;display:inline-block;vertical-align:top;"
              class="text-none ml-1"
              color="primary"
              dark
              @click="filterEvent"
            >
              <v-icon class="mr-1" style="font-size:17px;"
                >mdi-filter-outline</v-icon>
              Lọc
            </v-btn>
        </div>
      </v-col>

      <v-col class="pa-0 ma-0 pl-2 pr-2"
        cols="12" md="6" lg="6" xl="6">
        <div>
          <v-chip
            v-for="(item, idx) in levFilterArr"
            :key="'levF-' + idx"
            @click="selectFilter(item)"
            :outlined="item.selected"
            class="mx-1 my-1"
            :color="item.color"
            :text-color="item.color"
            style="font-weight: 600;"
            :style="{
              'background-color': item.selected
                ? 'white !important'
                : '#cfd8dc44 !important',
            }"
          >
            <v-avatar left>
              <v-icon>{{ item.icon }}</v-icon>
            </v-avatar>
              {{ item.numberEv }}
          </v-chip>
        </div>
      </v-col>
    </v-row>

    <v-row class="pa-0 px-0 ma-0 mt-2">
      <v-col cols="12"
        :class="{
          'px-1': $vuetify.breakpoint.mdAndUp,
          'px-0': $vuetify.breakpoint.smAndDown,
        }"
        class="pa-0 pt-0 ma-0">
        <events-table
          :filter="filter"
          :levFilterArr="levFilterArr"
          @filter-done="updateAfterFilter"
        ></events-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import EventsTable from "./EventsTable.vue"
  import moment from "moment"
  export default {
    components: {
      EventsTable,
    },
    data() {
      return {
        title: "Events Page",
        curDateObject: null,
        filter: {
          from: moment(Date.now()).format("YYYY-MM-DD 00:00:00"),
          to: moment(Date.now())
            .add(1, "days")
            .format("YYYY-MM-DD 00:00:00"),
        },
        levFilterArr: [
          {
            name: "normal",
            selected: true,
            color: "blue",
            icon: "mdi-information-outline",
            numberEv: 0,
          },
          {
            name: "warning",
            selected: true,
            color: "orange",
            icon: "mdi-alert-outline",
            numberEv: 0,
          },
          {
            name: "error",
            selected: true,
            color: "red",
            icon: "mdi-close-octagon-outline",
            numberEv: 0,
          },
          {
            name: "danger",
            selected: true,
            color: "purple",
            icon: "mdi-close-octagon",
            numberEv: 0,
          },
        ],
      }
    },
    methods: {
      onDateChanged(dateObj) {
        this.curDateObject = dateObj;
      },
      filterEvent() {
        if (!this.curDateObject) {
          return
        } else {
          if (this.curDateObject.type == "day") {
            this.filter = {
              from: moment(this.curDateObject.dateStrFull).format(
                "YYYY-MM-DD 00:00:00"
              ),
              to: moment(this.curDateObject.dateStrFull)
                .add(1, "days")
                .format("YYYY-MM-DD 00:00:00"),
            }
          } else if (this.curDateObject.type == "week") {
            this.filter = {
              from: moment(this.curDateObject.dateStrFull).format(
                "YYYY-MM-DD 00:00:00"
              ),
              to: moment(this.curDateObject.dateStrFull)
                .add(7, "days")
                .format("YYYY-MM-DD 00:00:00"),
            }
          } else if (this.curDateObject.type == "month") {
            this.filter = {
              from: moment(this.curDateObject.dateStrFull).format(
                "YYYY-MM-DD 00:00:00"
              ),
              to: moment(this.curDateObject.dateStrFull)
                .add(1, "months")
                .format("YYYY-MM-DD 00:00:00"),
            }
          } else if (this.curDateObject.type == "year") {
            this.filter = {
              from: moment(this.curDateObject.dateStrFull).format(
                "YYYY-MM-DD 00:00:00"
              ),
              to: moment(this.curDateObject.dateStrFull)
                .add(1, "years")
                .format("YYYY-MM-DD 00:00:00"),
            }
          } else if (this.curDateObject.type == "range") {
            this.filter = {
              from: moment(this.curDateObject.dateStrFull).format(
                "YYYY-MM-DD 00:00:00"
              ),
              to: moment(this.curDateObject.toDateStrFull)
                .format("YYYY-MM-DD 00:00:00"),
            }
          }
        }
      },
      selectFilter(item) {
        let idx = this.levFilterArr.findIndex((i) => i.name == item.name);
        this.levFilterArr[idx].selected = !this.levFilterArr[idx].selected;
      },
      updateAfterFilter(items) {
        //update levFilterArr
        this.levFilterArr.forEach((element) => {
          element.numberEv = 0;
          let arr = items.filter((i) => i.alarmLevel == element.name);
          element.numberEv = arr.length;
        });
      },
    },
    mounted() {
      this.$nextTick(() => {})
    },
  }
</script>

<style></style>
