var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",staticStyle:{"height":"100%","padding-top":"58px","padding-bottom":"46px","max-width":"3600px","background-color":"transparent","overflow-y":"auto"}},[_c('v-row',{staticClass:"pa-0 ma-0 mt-0",staticStyle:{"background-color":"#CFD8DC44","border":"1px solid #B0BEC566","max-width":"100vw"},attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0 ma-0 py-1",class:{
        'px-2': _vm.$vuetify.breakpoint.mdAndUp,
        'px-1': _vm.$vuetify.breakpoint.smAndDown,
      },staticStyle:{"min-width":"380px","max-width":"420px"},attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('date-picker',{staticStyle:{"display":"inline-block","vertical-align":"top"},on:{"dateChanged":_vm.onDateChanged}}),_c('v-btn',{staticClass:"text-none ml-1",staticStyle:{"min-width":"60px","max-width":"60px","height":"38px","display":"inline-block","vertical-align":"top"},attrs:{"color":"primary","dark":""},on:{"click":_vm.filterEvent}},[_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"17px"}},[_vm._v("mdi-filter-outline")]),_vm._v(" Lọc ")],1)],1)]),_c('v-col',{staticClass:"pa-0 ma-0 pl-2 pr-2",attrs:{"cols":"12","md":"6","lg":"6","xl":"6"}},[_c('div',_vm._l((_vm.levFilterArr),function(item,idx){return _c('v-chip',{key:'levF-' + idx,staticClass:"mx-1 my-1",staticStyle:{"font-weight":"600"},style:({
            'background-color': item.selected
              ? 'white !important'
              : '#cfd8dc44 !important',
          }),attrs:{"outlined":item.selected,"color":item.color,"text-color":item.color},on:{"click":function($event){return _vm.selectFilter(item)}}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_vm._v(" "+_vm._s(item.numberEv)+" ")],1)}),1)])],1),_c('v-row',{staticClass:"pa-0 px-0 ma-0 mt-2"},[_c('v-col',{staticClass:"pa-0 pt-0 ma-0",class:{
        'px-1': _vm.$vuetify.breakpoint.mdAndUp,
        'px-0': _vm.$vuetify.breakpoint.smAndDown,
      },attrs:{"cols":"12"}},[_c('events-table',{attrs:{"filter":_vm.filter,"levFilterArr":_vm.levFilterArr},on:{"filter-done":_vm.updateAfterFilter}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }